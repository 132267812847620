<template>
  <div class="main-view">
    <div class="card-content">
      <div class="content">
        <div v-if="auditRequest" class="columns">
          <dl-column
            title="Payment Request Info"
            :items="paymentRequestDisplay"
          ></dl-column>
        </div>
        <h3>Stages</h3>
        <b-table
          :data="auditRequest.stages"
          detailed
          :has-detailed-visible="(row) => !!row.value || !!row.error"
        >
          <b-table-column field="stage" label="Stage" sortable v-slot="props">
            {{ props.row.stage }}
          </b-table-column>
          <b-table-column field="status" label="Status" sortable v-slot="props">
             <span class="tag" :class="props.row.failed? 'is-danger': 'is-success'">
              {{ props.row.failed ? "Failed": "Sucess" }}
             </span>
          </b-table-column>
          <b-table-column field="timeTaken" label="Time Taken(ms)" sortable v-slot="props">
            {{ props.row.timeTaken }}
          </b-table-column>
          <template #detail="props">
             <b-field v-if="props.row.error" label="Stage error">
              <b-input
                disabled
                type="textarea"
                :value="props.row.error">
              </b-input>
            </b-field>
            <b-field v-if="props.row.value" label="Stage value">
              <b-input
                disabled
                type="textarea"
                :value="props.row.value">
              </b-input>
            </b-field>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import DlColumn from '@/components/DlColumn.vue';

export default {
  name: 'AudiRequestOverview',
  components: { DlColumn },
  methods: {
    createTimeItem(value) {
      const m = this.$moment;
      return {
        value: m(value).calendar(),
        toolTip: m(value).format('llll'),
      };
    },
  },
  computed: {
    paymentRequestDisplay() {
      return {
        Created: this.createTimeItem(this.auditRequest.createdAt),
        'Payment Plan ID': { value: this.auditRequest.paymentPlanId },
        Status: { value: this.auditRequest.status },
        'Final Stage': { value: this.auditRequest.finalStage || 'Unknown' },
      };
    },
  },
  data() {
    const { paymentPlanId, tenant } = this.$route.params;

    return {
      tenantCode: tenant,
      paymentPlanId,
      auditRequest: {},
    };
  },
  async created() {
    this.dataService = this.$dataService(this.tenantCode);
    const { data } = await this.dataService.auditRequests.get(this.paymentPlanId);
    this.auditRequest = data;
  },
};
</script>

<style lang="scss" scoped>
</style>
